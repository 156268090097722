<template>
  <div>
    <v-dialog
      v-model="addEditDialog"
      persistent
      max-width="500px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ editMode ? $t('Update Business Symbol') : $t('Add New Business Symbol') }}
          <v-spacer />
          <v-btn
            icon
            @click="closeDialog()"
          >
            <v-icon class="mdil-36px mdil-rotate-45">
              mdil-plus
            </v-icon>
          </v-btn>
        </v-card-title>

        <validation-observer
          ref="theForm"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            autocomplete="off"
            @submit.prevent="handleSubmit(submit)"
            @reset.prevent="reset"
          >
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  sm="6"
                  class="mb-4"
                >
                  <app-image-uploader
                    v-if="addEditDialog"
                    width="90%"
                    height="180px"
                    label="Logo"
                    :parent-image-url="formData.logo"
                    @image-selected="formData.logo = $event"
                  />
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Trademark Classification')"
                  >
                    <v-textarea
                      v-model="formData.activity"
                      :label="$t('Trademark Classification')"
                      outlined
                      dense
                      rows="3"
                      auto-grow
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  class="mb-4"
                >
                  <app-image-uploader
                    v-if="addEditDialog"
                    :only-image="false"
                    width="90%"
                    height="180px"
                    label="Trademark Ownership Order"
                    :parent-image-url="formData.ownership_order_attachment"
                    @image-selected="formData.ownership_order_attachment = $event"
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions class="py-3">
              <app-form-tip />

              <v-spacer />

              <v-btn
                depressed
                outlined
                small
                @click="closeDialog()"
              >
                {{ $t('Cancel') }}
              </v-btn>

              <v-btn
                type="submit"
                color="primary"
                depressed
                small
              >
                <v-icon left>
                  mdil-check
                </v-icon>
                <span class="pe-2">
                  {{ editMode ? $t('Save') : $t('Add') }}
                </span>
              </v-btn>
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    addEditDialog: { type: Boolean, default: false },
    formData: { type: Object, default: () => { } },
    editMode: { type: Boolean, default: false },
  },

  methods: {
    closeDialog() {
      this.$refs.theForm.reset()
      this.$emit('close')
    },

    submit() {
      let fd = new FormData()
      for (let key in this.formData) {
        if (this.formData[key]) {
          fd.append(key, this.formData[key])
        }
      }

      if (this.editMode) {
        axios.put(`company-business-symbol/${this.formData.id}/`, fd, { params: { company_id: this.formData.company } }).then(() => {
          this.$_notify('Updated successfully')
          this.closeDialog()
          this.$emit('reset-table-data')
        })
      } else {
        axios.post('company-business-symbol/', fd).then(() => {
          this.$_notify('Added successfully')
          this.closeDialog()
          this.$emit('reset-table-data')
        })
      }
    }
  },
}
</script>
